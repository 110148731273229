import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let routes = [
	{
		path: '/',
		name: 'home',
		component: () => import( /* webpackChunkName: "home" */ './views/Home/Home.vue')
	},
]

let router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	// eslint-disable-next-line
	scrollBehavior(to) {
		let top = 0;
		if (to.hash) {
			const element = document.querySelector(to.hash);
			if (element) {
				top = element.offsetTop;
			}
		}
		window.scrollTo({ top, behavior: 'smooth' });
	}
})

export default router