<template lang="pug">
    main#app
        Header
        router-view
        Footer
</template>

<script>
export default {
    name: "template-app",
    components: {
        Header: () => import( /* webpackChunkName: "Header" */ '@components/Header/Header'),
        Footer: () => import( /* webpackChunkName: "Footer" */ '@components/Footer/Footer'),
    }
}
</script>

<style lang="stylus" scoped src="./App.styl"></style>